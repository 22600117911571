<template>
  <div style="width: 100%; height: 100%; text-align: center; padding-top: 100px;" v-if="state.loaderEnabled">
    <div class="lds-ring"><img src="media/logos/Logo-Symbole-couleur.svg"/><div></div><div></div><div></div><div></div></div>
  </div>
  <transition name="fade">
    <div v-if="!state.loaderEnabled">
      <el-row :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="16" :xl="16">
          <div class="card mb-5 mb-xl-10" style="min-height: 310px;">
            <div class="card-body pt-9 pb-0">
              <div class="flex-grow-1">
                <div class="d-flex justify-content-between align-items-start flex-wrap">
                  <div class="d-flex flex-column">
                    <div class="d-flex align-items-center mb-2">
                    <inline-svg src="media/icons/duotune/general/gen026.svg" />
                      <span class="text-gray-800 fs-2 fw-bolder me-1">
                        Votre commande {{ state.mCmd.num_cmd_client }} du {{ formatDate(state.mCmd.toc_date_creation) }}
                      </span>
                    </div>
                    <div class="d-flex flex-wrap fw-bold fs-6 mb-1">
                      <div class="d-flex align-items-center text-gray-600 me-5 mb-2">
                        Concernant le projet : {{ state.mCmd.pro_nom_projet }}
                      </div>
                    </div>
                  </div>
                  <div class="d-flex my-4">
                    <span class="me-2 mb-2 btn btn-bg-light btn-sm btn-text-dark fs-5" style="cursor: default;">
                      {{ state.mCmd.etat_txt }}
                    </span>
                  </div>
                </div>
                <div class="d-flex flex-wrap flex-stack" style="background: #ebeff3; padding: 15px; border-radius: 5px;">
                  <div class="d-flex flex-column flex-grow-1 pe-8">
                    <el-row :gutter="20">
                      <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6" style="text-align: center;">
                        <div class="badge badge-white w-175px bt-5 text-dark" style="background: #ffffff00;">
                          Livraison estimée
                        </div>
                        <span v-if="!state.mCmd.date_ldc">
                          <div class="badge badge-light-danger w-175px bt-5 text-danger">
                            Date non communiquée
                          </div>
                        </span>
                        <div v-else>
                          <el-popover placement="top-start" title="Date de livraison" :width="300" trigger="hover" :content="formatHoverDateD(state.mCmd)">
                            <template #reference>
                              <span class="badge badge-white w-175px fs-6 text-gray-900" style="cursor: help; background: #ffffff00;">
                                {{ formatDate(state.mCmd.date_ldc) }}
                                <span
                                  class="svg-icon svg-icon-3 svg-icon-success ms-1"
                                  v-if="
                                    state.mCmd.date_old_ldc &&
                                    state.mCmd.date_old_ldc >
                                      state.mCmd.date_ldc
                                  "
                                >
                                  <inline-svg src="media/icons/duotune/arrows/arr066.svg" />
                                </span>
                                <span
                                  class="svg-icon svg-icon-3 svg-icon-danger ms-1"
                                  v-if="
                                    state.mCmd.date_old_ldc &&
                                    state.mCmd.date_old_ldc <
                                      state.mCmd.date_ldc
                                  "
                                >
                                  <inline-svg src="media/icons/duotune/arrows/arr065.svg" />
                                </span>
                              </span>
                            </template>
                          </el-popover>
                          <br />
                          <div class="badge badge-primary text-light w-175px bt-5">
                            Mise à jour {{ formatDateAgo(state.mCmd.fraicheur) }}
                          </div>
                        </div>
                      </el-col>
                      <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6" style="text-align: center;">
                        <div class="badge badge-white w-175px bt-5 text-dark" style="background: #ffffff00;">
                          Offre Stillnetwork
                        </div>
                        <span class="badge badge-white w-175px fs-6 text-gray-900" style="background: #ffffff00;">{{ state.mCmd.tof_code }} </span>
                      </el-col>
                      <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6" style="text-align: center;">
                        <div class="badge badge-white bt-5 text-dark" style="background: #ffffff00;">
                          Montants HT / TTC
                        </div>
                        <br />
                        <div class="text-gray-400" style="text-align: center;">
                          {{ formatMoney(state.mCmd.tof_total_ht) }} {{ state.mCmd.dev_sigle }}
                        </div>
                        <div class="text-gray-700" style="text-align: center; font-weight: 600;">
                          {{ formatMoney(state.mCmd.tof_total_ttc) }} {{ state.mCmd.dev_sigle }}
                        </div>
                      </el-col>
                      <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6" style="text-align: center;">
                        <img v-if="state.mCmd.const_logo" :src="toLogo(state.mCmd.const_logo)" style="width: auto; max-height: 75px; border-radius: 5px; max-width: 100%; height: auto;" />
                        <div v-else class="text-gray-700" style="text-align: center; font-weight: 600;">
                          {{ state.mCmd.const_nom }}
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
              <div class="d-flex flex-column flex-xl-row mt-3">
                <div class="flex-lg-row-fluid">
                  <ul class="nav nav-custom nav-pills border-0 fs-4 fw-bold mb-8" style="    margin: 0;  background: #ebeff3;  border-radius: 5px; margin-bottom: 16px !important;">
                    <li class="nav-item">
                      <router-link class="nav-link text-active-light me-6" :to="routeMenu('livraisons')" active-class="active">
                        Contenu de la commande
                      </router-link>
                    </li>
                    <li class="nav-item" v-if="state.mCmd.xSupport > 0">
                      <router-link class="nav-link text-active-light me-6" :to="routeMenu('support')" active-class="active">
                        Support et Abonnement ({{ state.mCmd.xSupport }})
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
          <div class="card mb-5 mb-xl-10" style="height: 310px !important;">
            <div class="card-body pt-9 pb-0">
              <div class="flex-grow-1">
                <div class="d-flex justify-content-between align-items-start flex-wrap mb-0">
                  <div class="d-flex flex-column">
                    <div class="d-flex align-items-center mb-2">
                      <a href="#" class="text-gray-800 text-hover-primary fs-2 fw-bolder me-1">Documents</a>
                    </div>
                  </div>
                </div>
                <div style="overflow-y: auto; height: 225px; padding: 10px;">
                  <template v-for="item in state.lGed" :key="item.ged_seq">
                    <div @click="gedPDF(item)" class="d-flex flex-aligns-center pe-10 pe-lg-20 mb-3 bg-hover-light">
                      <img alt="" class="w-30px me-3" :src="iconFile(item)" />
                      <div class="ms-1 fw-bold">
                        <span class="fs-7 text-dark fw-bolder">{{ item.ged_c_type }}</span>
                        <div class="text-gray-400">{{ item.ged_title }}</div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <router-view></router-view>
    </div>
  </transition>
  <el-drawer v-model="state.drawer" title="" :with-header="false" size="90%" modal-class="modal-offre">
    <div class="card shadow-none" style="width: 100%; height: 100%;">
      <div class="card-header" id="kt_activities_header">
        <h3 class="card-title fw-bolder text-dark" v-if="state.mDoc.ged_seq">
          Document {{ state.mDoc.ged_c_type }}
        </h3>
        <div class="card-toolbar">
          <button type="button" class="btn btn-sm btn-icon btn-active-light-primary me-n5" @click="state.drawer = false">
            <span class="svg-icon svg-icon-1">
              <inline-svg src="media/icons/duotune/arrows/arr061.svg" />
            </span>
          </button>
        </div>
      </div>
      <div class="card-body position-relative" id="kt_activities_body">
        <iframe
          :src="state.sourcePDF"
          frameborder="0"
          marginwidth="0"
          marginheight="0"
          scrolling="no"
          width="100%"
          height="100%"
          style="border: 1px solid #ccc; border-width: 1px; margin-bottom: 5px; max-width: 100%; widhth: 100%; height: 100%;"
          allowfullscreen
        >
        </iframe>
      </div>
    </div>
  </el-drawer>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import { useRouter } from "vue-router";
import mAxiosApi from "@/api";
import * as moment from "moment";

export default defineComponent({
  name: "Display-commande",
  props: {
    mCode: String,
  },
  setup(props) {
    moment.default.locale("fr");

    const state = reactive({
      loaderEnabled: true,
      mCmd: [],
      lGed: [],
      mDoc: [],
      sourcePDF: "",
      drawer: false,
      search: "",
    });
    const router = useRouter();
    const mCode =
      props.mCode && props.mCode.length > 0
        ? props.mCode
        : (router.currentRoute.value.params.code as string);

    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };
    const formatDateAgo = (d) => {
      return moment.default(d, "YYYY-MM-DD").fromNow();
    };

    const formatMoney = (n) => {
      if (!n) n = 0;
      return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    };

    const formatHoverDateD = (data) => {
      if (!data.date_old_ldc) {
        return `La date de livraison n'a connue aucune variation depuis le ${formatDate(
          data.toc_date_creation
        )} `;
      }
      return `${formatDateAgo(
        data.fraicheur
      )}, la date de livraison est passée du ${formatDate(
        data.date_old_ldc
      )} au ${formatDate(data.date_ldc)}`;
    };

    const toLogo = (logo) => {
      return "data:image/jpg;base64," + logo;
    };

    const gedPDF = async (mDoc) => {
      state.mDoc = mDoc;
      const getGedByTypeId = await getAxios("/getGedByTypeId/Seq/" + mDoc.ged_seq );
      const records = getGedByTypeId.records;

      if (mDoc.ged_title.toUpperCase().indexOf(".PDF") > 0) {
        const srcPDF = "data:application/pdf;base64," + records[0].ged_document;
        state.sourcePDF = srcPDF;
        state.drawer = true;
      } else {
        const linkSource = `data:application/msg;base64,${records[0].ged_document}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = mDoc.ged_title;
        downloadLink.click();
      }
    };

    const iconFile = (mDoc) => {
      if (mDoc.ged_title.toUpperCase().indexOf(".PDF") > 0) {
        return "media/svg/files/pdf.svg";
      }
      if (mDoc.ged_title.toUpperCase().indexOf(".DOC") > 0) {
        return "media/svg/files/doc.svg";
      }
      return "media/svg/files/all-svg.svg";
    };

    onMounted(async () => {
      MenuComponent.reinitialization();
      let myList = await getAxios(`/getCommandes/${mCode}`);
      state.mCmd = myList.results[0];

      const item: any = state.mCmd;
      item.etat_txt = "En cours de traitement";
      if (
        item.toc_etat_livraison_min == 1 &&
        item.toc_etat_livraison_max == 2
      ) {
        item.etat_txt = "Partiellement livrée";
      }
      if (
        item.toc_etat_livraison_min == 2 &&
        item.toc_etat_livraison_max == 2
      ) {
        item.etat_txt = "Commande livrée";
      }

      let myGed = await getAxios(`/getGed/${mCode}`);
      state.lGed = myGed.results;
      setCurrentPageBreadcrumbs("Détail d'une commande", []);

      state.loaderEnabled = false;
    });
    const routeMenu = (val) => {
      return `/commande/${mCode}/${val}`;
    };

    return {
      routeMenu,
      state,
      formatDate,
      formatDateAgo,
      formatHoverDateD,
      gedPDF,
      iconFile,
      formatMoney,
      toLogo,
    };
  },
});
export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
</script>
